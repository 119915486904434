import React, { useState, useEffect, useRef, useCallback } from "react";
import "./ChatContainer.scss";
import ragIcon from "../../images/rag.png";
import devAssistantIcon from "../../images/dev_x.png";
import { IoSend } from "react-icons/io5";
import { FaStopCircle } from "react-icons/fa";
import { GiSpellBook } from "react-icons/gi";
import { FcBookmark } from "react-icons/fc";
import { ImBooks } from "react-icons/im";
import { FaQuestion } from "react-icons/fa";
import Feedback from "../Feedback/Feedback";
// import ChatGuide from "../ChatGuide/ChatGuide";
import GeneratingMessage from "../GeneratingMessage/GeneratingMessage";
import Message from "./Message";
import Reference from "./Reference";
// import FAQ from "../FAQ/FAQ";
import ReactMarkdown from "react-markdown";
import axios from "axios";

const ChatContainer = ({
  isSidebarOpen,
  isDevMode,
  handleDevMode,
  handleOpenFile,
  currentServer,
  userInfo,
  isRAG,
  handleOpenRAGChat,
  handleOpenMailChat,
  handleOpenGuide,
  showGuide,
  currentMessages,
}) => {
  const [faqItems, setFaqItems] = useState([]);
  const [messages, setMessages] = useState([]);
  const [generateMessage, setGenerateMessage] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const textareaRef = useRef(null);
  const messagesEndRef = useRef(null);
  const updateMessageRef = useRef("");
  const controllerRef = useRef(null);
  const formRef = useRef(null);
  const currentSessionId = useRef(0);
  const isFetchingMessages = useRef(false); // 현재 메시지를 가져오는 중인지 여부를 의미

  //Kc-> KC 대문자로 표기
  function formatCompanyName(company) {
    // 'Kc'로 시작하는 부분을 찾아서 'KC'로 변경
    return company.replace(/Kc/g, "KC");
  }

  // ** 첫 메세지에 FAQ 포함하기 ** //
  const fetchFAQs = useCallback(async () => {
    try {
      const response = await axios.get(
        `${currentServer}/get_faqs?company=${userInfo.company}`
      );
      if (response.status === 200 && response.data) {
        setFaqItems(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentServer, userInfo.company]);

  const fetchLastSessionId = useCallback(async () => {
    try {
      const response = await axios.get(
        `${currentServer}/get_last_session?user_id=${userInfo.id}`
      );
      if (response.status === 200 && response.data) {
        currentSessionId.current = response.data;
      }
    } catch (error) {
      console.log(error);
    }
  }, [currentServer, userInfo.id]);

  // ** 첫 메세지에 session을 통해 히스토리 불러오기 ** //
  const fetchMessages = useCallback(async () => {
    try {
      const response = await axios.post(
        `${currentServer}/get_chat_messages_from_session`,
        {
          user_id: userInfo.id,
          session_id: currentSessionId.current,
        }
      );
      setMessages((prevMessages) => [...prevMessages, ...response.data]);
    } catch (error) {
      console.log(error);
    }
  }, [currentServer, userInfo.id, currentSessionId]);

  useEffect(() => {
    if (isFetchingMessages.current) return;
    // 두 번 호출 되지 않도록 방지
    isFetchingMessages.current = true;

    // ** FAQ와 메세지 순서대로 불러오기 ** //
    fetchFAQs()
      .then(() => {
        return fetchLastSessionId();
      })
      .then(() => {
        return fetchMessages();
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fetchFAQs, fetchMessages, fetchLastSessionId]);

  // const onSelectFAQ = (item) => {
  //   setInputMessage(item);
  //   setTimeout(() => {
  //     if (formRef.current) {
  //       formRef.current.dispatchEvent(
  //         new Event("submit", { cancelable: true, bubbles: true })
  //       );
  //     }
  //   }, 0);
  // };

  useEffect(() => {
    if (faqItems.length > 0 && messages.length === 0) {
      // const faqContent = (
      //   <div>
      //     <p>아래는 제가 답해드릴 수 있는 예시 질문들입니다:</p>
      //     <div className="faq-buttons-container">
      //       {faqItems.map((item, index) => (
      //         <button
      //           key={`faq-${index}`}
      //           onClick={() => onSelectFAQ(item)}
      //           className="faq-button"
      //           // style={{ animationDelay: `${index * 0.2}s` }}
      //         >
      //           <FcBookmark /> &nbsp;
      //           {item}
      //         </button>
      //       ))}
      //     </div>
      //   </div>
      // );

      const initialMessage = {
        role: "assistant",
        content: `안녕하세요 ${userInfo.name}님! 저는 ${formatCompanyName(
          userInfo.company
        )}의 복리후생과 사규 내용과 관련된 질문들에 대답해 드릴 수 있어요. \n\nFAQ가 궁금하시다면 하단의 'FAQ 보기' 버튼을 눌러주세요.`,
        // faqButtons: faqContent,
        fileViewers: null,
        isFeedbackEnabled: false,
      };

      setMessages([initialMessage]);
    }
  }, [faqItems, messages.length, userInfo.name, userInfo.company]);

  // ** 채팅창 자동 올리기 ** //
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, [inputMessage]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // ** chat history 표시하기 ** //
  useEffect(() => {
    if (currentMessages) {
      currentSessionId.current = currentMessages.session;
      setMessages(currentMessages.messages); // 세션 대화로 업데이트
    } else {
      setMessages([]); // 초기 화면은 빈 상태
    }
  }, [currentMessages]);

  // ** 답변 로직 ** //
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim() || isGenerating) return;

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", content: inputMessage, isFeedbackEnabled: false },
    ]);
    setInputMessage("");

    const controller = new AbortController();
    controllerRef.current = controller;

    try {
      const response = await fetch(`${currentServer}/chat_api_gateway`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: userInfo.id,
          session_id: currentSessionId.current,
          company: userInfo.company,
          requset_type: "policy",
          content: inputMessage,
        }),
        signal: controller.signal,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      setIsGenerating(true);
      updateMessageRef.current = "";
      // fileObjectsRef.current = [];
      let buffer = ""; // 스트림 데이터를 임시로 저장할 버퍼
      const references = [];
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        // 스트림에서 데이터를 읽고 디코딩
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;

        // 구분자 "\n\n"을 기준으로 데이터를 분리
        let parts = buffer.split("\n\n");

        // 마지막 요소는 완전한 데이터가 아닐 수 있으므로 버퍼에 남겨둠
        buffer = parts.pop();
        // 각 완전한 응답을 처리
        for (const part of parts) {
          try {
            const chatObject = JSON.parse(part);
            // 데이터를 처리하는 로직
            if ("message_id" in chatObject) {
              // Finish 정보
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  role: "assistant",
                  references: references,
                  content: updateMessageRef.current,
                  isFeedbackEnabled: true,
                  messageId: chatObject.message_id,
                },
              ]);
            } else if ("source" in chatObject) {
              // Reference 정보
              references.push(chatObject);
            } else if ("content" in chatObject) {
              // 일반 메세지
              setGenerateMessage((prev) => {
                updateMessageRef.current = prev + chatObject.content;
                return prev + chatObject.content;
              });
            } else {
              // 에러 발생해야 한다
              console.error("Unknown response:", chatObject);
            }
          } catch (error) {
            console.error("Failed to parse JSON:", error);
          }
        }
      }
    } catch (err) {
      if (err.name === "AbortError") {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            role: "assistant",
            content: updateMessageRef.current,
            isFeedbackEnabled: false,
          },
        ]);
      } else {
        console.error("스트림 에러:", err);
      }
    } finally {
      setIsGenerating(false);
      setGenerateMessage("");
      controllerRef.current = null;
    }
  };

  const stopGenerating = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
      controllerRef.current = null;
      setIsGenerating(false);
    }
  };

  // const Message = ({ content, faqButtons, references }) => {
  //   return (
  //     <div>
  //       <ReactMarkdown>{content}</ReactMarkdown>
  //       {faqButtons && faqButtons}
  //       {references && references.length > 0 && (
  //         <>
  //           <button onClick={() => setIsPopupOpen(true)} className="pdfButton">
  //             📂 참고한 내용 보기
  //           </button>
  //           <Reference
  //             isOpen={isPopupOpen}
  //             onClose={() => setIsPopupOpen(false)}
  //             references={references}
  //           />
  //         </>
  //       )}
  //     </div>
  //   );
  // };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  // FAQ 선택하면 답변
  const onSelectFAQ = useCallback(
    (item) => {
      setInputMessage(item);
      setTimeout(() => {
        if (formRef.current) {
          formRef.current.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
          );
        }
      }, 0);
    },
    [formRef]
  );
  // FAQ 보여주기
  const fetchFAQ = useCallback(async () => {
    const question = "어떤 사규 내용들을 참고하고 있어?";
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: "user",
        content: question,
        isFeedbackEnabled: false,
      },
    ]);

    try {
      const response = await axios.get(
        `${currentServer}/get_faqs?company=${userInfo.company}`
      );

      if (response.status === 200 && response.data) {
        const faqList = response.data;

        // FAQ 버튼 생성
        const faqContent = (
          <div>
            <div className="faq-buttons-container">
              {faqList.map((item, index) => (
                <button
                  key={`faq-${index}`}
                  onClick={() => onSelectFAQ(item)}
                  className="faq-button"
                >
                  <FcBookmark /> &nbsp; {item}
                </button>
              ))}
            </div>
          </div>
        );

        // FAQ 메시지 생성 및 추가
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            role: "assistant",
            content:
              "아래는 제가 답변해드릴 수 있는 질문 목록입니다. \n\n질문을 클릭해보세요!",
            faqButtons: faqContent,
            isFeedbackEnabled: false,
          },
        ]);
      }
    } catch (error) {
      console.log("Failed to fetch FAQs:", error);
    }
  }, [currentServer, userInfo.company, onSelectFAQ]);

  // 참고한 사규 리스트 api //
  const fetchDocumentList = async () => {
    const question = "어떤 사규 내용들을 참고하고 있어?";
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        role: "user",
        content: question,
        isFeedbackEnabled: false,
      },
    ]);

    const controller = new AbortController();
    controllerRef.current = controller;
    try {
      const response = await fetch(`${currentServer}/chat_api_gateway`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: userInfo.id,
          session_id: currentSessionId.current,
          company: userInfo.company,
          requset_type: "reference",
          content: "",
        }),
        signal: controller.signal,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      setIsGenerating(true);
      updateMessageRef.current = "";
      let buffer = ""; // 스트림 데이터를 임시로 저장할 버퍼

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        // 스트림에서 데이터를 읽고 디코딩
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;

        // 구분자 "\n\n"을 기준으로 데이터를 분리
        let parts = buffer.split("\n\n");

        // 마지막 요소는 완전한 데이터가 아닐 수 있으므로 버퍼에 남겨둠
        buffer = parts.pop();

        // 각 완전한 응답을 처리
        for (const part of parts) {
          try {
            const chatObject = JSON.parse(part);
            // 데이터를 처리하는 로직
            if ("message_id" in chatObject) {
              // Finish 정보
              setMessages((prevMessages) => [
                ...prevMessages,
                {
                  role: "assistant",
                  references: [],
                  content: updateMessageRef.current,
                  isFeedbackEnabled: true,
                  messageId: chatObject.message_id,
                },
              ]);
            } else if ("content" in chatObject) {
              // 일반 메세지
              setGenerateMessage((prev) => {
                updateMessageRef.current = prev + chatObject.content;
                return prev + chatObject.content;
              });
            } else {
              // 에러 발생해야 한다
              console.error("Unknown response:", chatObject);
            }
          } catch (error) {
            console.error("Failed to parse JSON:", error);
          }
        }
      }
    } catch (err) {
      if (err.name === "AbortError") {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            role: "assistant",
            content: updateMessageRef.current,
            isFeedbackEnabled: false,
          },
        ]);
      } else {
        console.error("스트림 에러:", err);
      }
    } finally {
      setIsGenerating(false);
      setGenerateMessage("");
      controllerRef.current = null;
    }
  };

  // ** 렌더링 ** //
  return (
    <div className={`chat-container ${isSidebarOpen ? "sidebar-open" : ""}`}>
      <div className="chat-header">
        <div id="menu">
          <button id="active" onClick={handleOpenRAGChat}>
            복리후생봇
          </button>
          {/* <button id="deactive" onClick={handleOpenMailChat}>
            메일 검토
          </button> */}
        </div>
        <img
          src={isDevMode ? devAssistantIcon : ragIcon}
          alt="Assistant"
          className="assistant-icon"
          onClick={handleDevMode}
        />
      </div>

      <div className="messages-container">
        {/* <ChatGuide /> */}
        {/* {messages.length === 0 ? (
          <FAQ
            currentServer={currentServer}
            onSelectFAQ={onSelectFAQ}
            company={userInfo.company}
          />
        ) : null} */}
        {showGuide ? null : (
          <>
            {" "}
            {messages.map((msg, index) => (
              <div key={index} className={`message-wrapper ${msg.role}`}>
                <div className={`message ${msg.role}`}>
                  <div className="message-content">
                    <Message
                      content={msg.content}
                      references={msg.references}
                      faqButtons={msg.faqButtons}
                    />
                  </div>
                </div>
                {msg.role === "assistant" && msg.isFeedbackEnabled && (
                  <Feedback
                    messageId={messages[index].messageId}
                    userInfo={userInfo}
                  />
                )}
              </div>
            ))}
            {generateMessage.length > 0 && (
              <div className="message-wrapper assistant">
                <div className="message assistant">
                  <div className="message-content generating">
                    <GeneratingMessage content={generateMessage} />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div ref={messagesEndRef} />
      </div>

      <button className="onBoardingGuide" onClick={handleOpenGuide}>
        <GiSpellBook /> &nbsp; 가이드 보기
      </button>

      <button className="onBoardingGuide" onClick={fetchFAQ}>
        <FaQuestion /> &nbsp; FAQ 보기
      </button>

      <button className="onBoardingGuide" onClick={fetchDocumentList}>
        <ImBooks /> &nbsp; 참고한 사규 리스트
      </button>

      <form ref={formRef} className="input-form" onSubmit={handleSubmit}>
        <textarea
          type="text"
          ref={textareaRef}
          value={inputMessage}
          onChange={(e) => {
            setInputMessage(e.target.value);
          }}
          placeholder="복리후생에 관해 궁금한 점을 질문해 주세요"
          disabled={isGenerating}
          rows={1}
          onKeyPress={handleKeyPress}
          maxLength="500"
        />
        {isGenerating ? (
          <button type="button" className="stop" onClick={stopGenerating}>
            <FaStopCircle />
          </button>
        ) : (
          <button type="submit">{<IoSend />}</button>
        )}
      </form>
    </div>
  );
};

export default ChatContainer;
