import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import Reference from "./Reference";

const Message = ({ content, faqButtons, references }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div>
      <ReactMarkdown>{content}</ReactMarkdown>
      {faqButtons && faqButtons}
      {references && references.length > 0 && (
        <>
          <button onClick={() => setIsPopupOpen(true)} className="pdfButton">
            📂 참고한 내용 보기
          </button>
          <Reference
            isOpen={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            references={references}
          />
        </>
      )}
    </div>
  );
};

export default Message;
