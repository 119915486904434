import React, { useState } from "react";
import "./OnboardingGuide.scss";
import ragIcon from "../../images/rag.png";
import mailIcon from ".././../images/mail.png";
import buttonIcon from ".././../images/button.png";
import linkImage from ".././../images/link.png";
const OnboardingGuide = ({ onComplete, userInfo, startRAG, startMail }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedFeature, setSelectedFeature] = useState(null);

  const handleDontShowAgain = () => {
    onComplete();
    localStorage.setItem("hideGuidePopup", "true");
  };

  const guideSteps = [
    {
      title: ` ${userInfo.name}님 환영합니다! 👋`,
      content: ["가이드를 통해 복리후생봇의 기능에 대해 자세히 알아볼까요?"],
      img: "",
      update: "2024년 12월 18일",
    },
    {
      title: "1. 복리후생 질답 기능 살펴보기 💼",
      content: [
        "회사 복리후생 및 사규에 대한 궁금증을 해결해 드리는 챗봇입니다.",
        "1. 답변 하단의 '참고한 내용 보기'를 클릭하시면 어떤 사규를 참고했는지 알 수 있습니다.",
        "2. FAQ가 궁금하시다면 'FAQ 보기' 버튼을 클릭해 주세요.",
        "3. 챗봇이 어떤 사규들을 알고있는지 궁금하시다면 '참고한 사규 리스트' 버튼을 클릭해 주세요.",
      ],
      img: linkImage,
    },
    {
      title: "2.메뉴 탭 활용하기 💡",
      content: [
        "메뉴 탭에서 이전 대화 목록을 보실 수 있습니다.",
        "또한 문의사항을 작성하실 수 있습니다. 원하는 기능이 있다면 의견을 말씀해 주세요!",
      ],
      img: buttonIcon,
    },
    {
      title: "3. 유의 사항 ⚠️",
      content: [
        "✅ LLM은 구체적인 질문을 할수록 더 정확한 답변을 제공합니다.",
        "예를 들어, ‘대출금 알려줘’보다는 ‘주택구입관련 대출에 대해 알려줘’와 같이 특정 세부사항을 포함한 질문을 하면, 챗봇이 더욱 정교하고 관련성 높은 답변을 제공할 수 있습니다.",
        "",
        "✅ LLM이 제공하는 답변은 회사의 내부 자료에 기반하지만, 간혹 오해의 소지가 있는 정보가 포함될 수 있습니다.",
        "중요한 결정을 내리기 전 원본 문서를 확인해 주세요.",
      ],
      img: "",
    },
    {
      title: "시작하기 ✨",
      content: ["가이드를 마쳤습니다!", "이제 챗봇을 만나러 가볼까요!"],
      isFeatureSelection: true,
      icon: mailIcon,
    },
  ];

  // const options = [
  //   {
  //     title: "복리후생 질답",
  //     content: "복리후생에 관련된 내용을 답변해 드립니다.",
  //     type: "RAG",
  //     img: ragIcon,
  //   },
  //   {
  //     title: "메일 작성",
  //     content: "키워드에 맞게 메일을 작성해 드립니다.",
  //     type: "Mail",
  //     img: mailIcon,
  //   },
  // ];

  const handleNext = () => {
    // 마지막 단계에서는 기능이 선택되어야만 다음으로 진행
    if (currentStep === guideSteps.length - 1 && !selectedFeature) {
      return;
    }

    if (currentStep < guideSteps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleSkip = () => {
    // if (onComplete && selectedFeature) {
    //   if (selectedFeature === "RAG") {
    //     startRAG();
    //     onComplete();
    //   } else {
    //     startMail();
    //     onComplete();
    //   }
    // }
    onComplete();
  };

  const handleClose = () => {
    onComplete();
  };

  const handleFeatureSelect = (feature) => {
    setSelectedFeature(feature);
  };

  const currentStepData = guideSteps[currentStep];

  return (
    <div className="onboarding-overlay">
      <div className="onboarding-card">
        <button className="close-button" onClick={handleClose}>
          <span className="close-icon"></span>
        </button>

        <div className="card-content">
          <div className="content-wrapper">
            <h2 className="title">{currentStepData.title}</h2>

            <div className="content-with-icon">
              <div className="text-content">
                {currentStepData.content.map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </div>
              {currentStepData.icon && (
                <img
                  className="content-icon"
                  src={currentStepData.icon}
                  alt="icon"
                />
              )}
            </div>

            {currentStepData.img && (
              <img className="guideImage" src={currentStepData.img} alt="img" />
            )}

            {currentStepData.update && (
              <i>
                마지막 업데이트: <b>{currentStepData.update}</b>
              </i>
            )}
          </div>

          {/* 기능이 여러개일때, 마지막 페이지에서 선택하도록 */}
          {/* {currentStepData.isFeatureSelection && (
              <div className="feature-selection">
                {options.map((option, index) => (
                  <button
                    key={index}
                    className={`feature-button ${
                      selectedFeature === option.type ? "selected" : ""
                    }`}
                    onClick={() => handleFeatureSelect(option.type)}
                  >
                    <img className="feature-icon" src={option.img} alt="mail" />
                    <div className="feature-text">
                      <h3>{option.title}</h3>
                      <p>{option.content}</p>
                    </div>
                  </button>
                ))}
              </div>
            )} */}

          <div className="controls">
            <button
              className="nav-button"
              onClick={handlePrev}
              disabled={currentStep === 0}
            >
              <span className="arrow-left"></span>
              이전
            </button>

            <div className="indicators">
              {guideSteps.map((_, index) => (
                <div
                  key={index}
                  className={`indicator ${
                    index === currentStep ? "active" : ""
                  }`}
                />
              ))}
            </div>

            {currentStep < guideSteps.length - 1 ? (
              <button className="nav-button" onClick={handleNext}>
                다음
                <span className="arrow-right"></span>
              </button>
            ) : (
              <button
                className="nav-button start"
                onClick={handleSkip}
                // disabled={!selectedFeature}
              >
                시작하기
              </button>
            )}
          </div>
          <div className="dontShowAgain">
            <button onClick={handleDontShowAgain}>다시 보지 않기</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingGuide;
