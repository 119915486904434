import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import "./Sidebar.scss";
import axios from "axios";
import { SERVER_URL } from "../../config";
import ragIcon from "../../images/rag.png";
import mailIcon from ".././../images/mail.png";

function Sidebar({
  toggleSidebar,
  userInfo,
  isRAG,
  handleOpenRAGChat,
  handleOpenMailChat,
  sessions,
  fetchChatHistory,
}) {
  const [inquiry, setInquiry] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);

  const handleSessionClick = (sessionId) => {
    setSelectedSessionId(sessionId);
    fetchChatHistory(userInfo.id, sessionId);
  };

  // updated date 표시
  function formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);

    const formattedDate = date.toISOString().split("T")[0];

    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;

    return `${formattedDate} / ${formattedTime}`;
  }

  // chat history, session별 목록
  const ChatHistoryList = () => {
    console.log(sessions);

    return (
      <div className="sorted-list-wrapper">
        <div className="sorted-list">
          <ul>
            {sessions.map((item) => (
              <li
                key={item.id}
                onClick={() => handleSessionClick(item.id)}
                className={selectedSessionId === item.id ? "selected" : ""}
              >
                <i>{formatDateTime(item.updated_date)}</i> &nbsp;
                {item.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  const handleInquirySubmit = async (e) => {
    e.preventDefault();
    setInquiry(e.target.value);
    try {
      await axios.post(`${SERVER_URL}/add_user_inquiry`, {
        user_id: userInfo.id,
        content: inquiry,
      });
      setIsSubmitted(true);
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };

  // useEffect(() => {
  //   // TODO get sessions
  // }, []);

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <h2>메뉴</h2>
        <button onClick={toggleSidebar} className="close-sidebar">
          <IoMdClose />
        </button>
      </div>
      {/* <hr></hr>
      <div className="sidebar-switchMode">
        <button
          onClick={handleOpenRAGChat}
          className={`${isRAG ? "active" : "deactive"}`}
        >
          <img src={ragIcon} id="icon" alt="rag" />
          사규 내용에 답해드려요
        </button>
        <br></br>
        <button
          onClick={handleOpenMailChat}
          className={`${isRAG ? "deactive" : "active"}`}
        >
          <img src={mailIcon} id="icon" alt="mail" />
          이메일을 검토해 드려요
        </button>
      </div>
      <hr></hr> */}
      <hr></hr>
      <h3>이전 대화</h3>
      <ChatHistoryList />
      <hr></hr>
      <form onSubmit={handleInquirySubmit}>
        <h3>문의사항</h3>
        {!isSubmitted ? (
          <>
            <textarea
              value={inquiry}
              onChange={(e) => setInquiry(e.target.value)}
              placeholder="답장받으실 메일 주소도 작성 부탁드립니다."
            />
            <button type="submit">제출</button>{" "}
          </>
        ) : (
          <div>제출 되었습니다.</div>
        )}
      </form>
    </div>
  );
}

export default Sidebar;
